import "styles/pages/page-home.scss";
import React from "react";
import { graphql } from "gatsby";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SectionTitle from "components/SectionTitle";
import Breadcrumbs from "components/Breadcrumbs";
import CollapseTile from "components/CollapseTile";

const OperatorsPage = ({ pageContext, data }) => {
   const seo = pageContext.seo;
   const operators = data.allWpOperator.nodes;
   return (
      <Layout>
         <Seo
            title={seo.title ? seo.title : pageContext.title}
            description={seo.metaDesc}
         />
         <Breadcrumbs currentLocationLabel={pageContext.title} />
         <section className="operators-page">
            <div className="container">
               <SectionTitle title={pageContext.title} />
               <div className="row">
                  {operators.map((obj, index) => {
                     const item = obj.cptOperators;
                     return (
                        <div
                           className="col-lg-6 home-operators__tile"
                           key={index}
                        >
                           <CollapseTile
                              logo={item.logo}
                              name={item.name}
                              subtitle={item.address}
                              webpage={item.url}
                              email={item.email}
                              phones={item.phones}
                              activityRegion={item.activityRegion}
                              staff={item.staff}
                           />
                        </div>
                     );
                  })}
               </div>
            </div>
         </section>
      </Layout>
   );
};

export const query = graphql`
   query {
      allWpOperator(sort: { fields: cptOperators___name, order: ASC }) {
         nodes {
            cptOperators {
               logo {
                  sourceUrl
                  altText
               }
               activityRegion
               address
               email
               phones {
                  phone
               }
               name
               url
               staff {
                  name
                  group {
                     image {
                        sourceUrl
                        altText
                     }
                     email
                     name
                     phone
                     url
                  }
               }
            }
         }
      }
   }
`;

export default OperatorsPage;
